import React, { useContext, useState } from "react";
import { Typography, message, Spin } from "antd";
import AqItem from "../../../Core/AqItem";
import AqInput from "../../../Core/AqInput";
import { I18nContext } from "../../../../I18n";
import userIcon from "../../../../Assets/Icons/user.svg";
import emailIcon from "../../../../Assets/Icons/email.svg";
import AqButton from "../../../Core/AqButton";
import { ASSETS_URL, MESSAGE_DURATION } from "../../../../Consts/config";
import { createOrder } from "../../../../Apis/orders";
import { isSuccessful, toAqResponse } from "../../../../Utils/responses";
import { s500 } from "../../../../Consts/responses";
import { LoadingOutlined } from "@ant-design/icons";
import WaterSearchContext from "../../../../Contexts/WaterSearchContext/context";
import { validEmail, validName } from "../../../../Utils/validation";

const { Title } = Typography;

const OrderForm = ({ onSubmit, productInfo }) => {
  const { translate } = useContext(I18nContext);
  const [name, setName] = useState([]);
  const [email, setEmail] = useState([]);
  const [ordering, setOrdering] = useState(false);
  const { waterSearch } = useContext(WaterSearchContext);

  const onChangeName = (newValue) => setName(newValue.target.value);
  const onChangeEmail = (newValue) => setEmail(newValue.target.value);

  const onClickSubmit = async () => {
    setOrdering(true);
    try {
      validEmail(email);
      validName(name);

      const filterId = waterSearch?.filter?.id;
      const regularWaterId = waterSearch?.regular?.id;
      const sparklingWaterId = waterSearch?.sparkling?.id;
      const locationId = waterSearch?.location?.id;
      const address = waterSearch?.address;
      const zip = waterSearch?.zip;
      const product = productInfo.name;

      const orderData = {
        name,
        email,
        product,
        filterId,
        locationId,
        regularWaterId,
        sparklingWaterId,
        address,
        zip,
      };
      const orderResRaw = await createOrder(orderData);
      const orderRes = toAqResponse(orderResRaw.data, s500.SERVER_ERROR);
      if (!isSuccessful(orderRes)) throw orderRes;
      onSubmit();
    } catch (error) {
      const errorCode = error?.response?.data?.code || error?.code;
      console.error(`❌ ${error}`);
      if (translate(`responses.${errorCode}`, {}, true)) {
        message.error(translate(`responses.${errorCode}`), MESSAGE_DURATION);
      } else {
        message.error(
          translate(`responses.${s500.SERVER_ERROR.code}`),
          MESSAGE_DURATION
        );
      }
    }
    setOrdering(false);
  };

  const renderImage = (imgSrc) => {
    return <img src={imgSrc} alt="icon" />;
  };

  return (
    <div className="product__order-modal">
      <Title level={3} className="typography h3">
        {`${translate(
          `pages.product.${productInfo.available ? "order" : "pre_order"}`
        )} ${productInfo.name}`}
      </Title>
      <img
        className="product__order-modal__image"
        src={`${ASSETS_URL}/${productInfo.smallImage}`}
        alt="product"
      />
      <div>
        <AqItem label={renderImage(userIcon)}>
          <AqInput
            placeholder={translate(
              "pages.product.order_modal.name_input_placeholder"
            )}
            value={name}
            onChange={onChangeName}
          />
        </AqItem>
        <AqItem label={renderImage(emailIcon)}>
          <AqInput
            placeholder={translate(
              "pages.product.order_modal.email_input_placeholder"
            )}
            value={email}
            onChange={onChangeEmail}
            onEnter={onClickSubmit}
          />
        </AqItem>
      </div>
      <AqButton type="primary" onClick={onClickSubmit} disabled={ordering}>
        {ordering ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          translate(
            `pages.product.${productInfo.available ? "order" : "pre_order"}`
          )
        )}
      </AqButton>
    </div>
  );
};

export default OrderForm;
