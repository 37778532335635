import React from "react";
import { Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import AqTooltip from "../../Core/AqTooltip";

const { Text } = Typography;

const ItemRow = ({ title, selections, tooltip }) => {
  return (
    <div className="products__table-item-row">
      <div className="with-tooltip">
        <Text>{title}</Text>
        {tooltip && <AqTooltip text={tooltip} />}
      </div>
      {selections.map((selection, index) => (
        <div key={index}>{selection && <CheckOutlined />}</div>
      ))}
    </div>
  );
};

export default ItemRow;
