class AqResponse {
  constructor(status, code, data = {}) {
    this.status = status;
    this.code = code;
    this.data = data;
    this.timestamp = Date.now();
    this.objectType = "AqResponse";
  }
}

export default AqResponse;
