import React from "react";
import { Button } from "antd";
import {
  ABOUT_URL,
  TERMS_OF_SERVICE_URL,
  CONTACT_URL,
  PRIVACY_POLICY_URL,
} from "../../Consts/config";

const AqFooter = () => {
  return (
    <div className="aqfooter">
      <Button type="text">
        <a href={ABOUT_URL}>About</a>
      </Button>
      <Button type="text">
        <a href={TERMS_OF_SERVICE_URL}>Terms of Use</a>
      </Button>
      <Button type="text">
        <a href={CONTACT_URL}>Contact</a>
      </Button>
      <Button type="text">
        <a href={PRIVACY_POLICY_URL}>Privacy Policy</a>
      </Button>
    </div>
  );
};

export default AqFooter;
