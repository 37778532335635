import React, { useContext } from "react";
import { I18nContext } from "../../I18n";

const AqBeta = () => {
  const { translate } = useContext(I18nContext);

  return <div className="aqbeta">{translate("pages.home.beta_banner")}</div>;
};

export default AqBeta;
