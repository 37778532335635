import React, { useEffect, useState, useContext } from "react";
import AqPageLayout from "../Components/Layouts/Page";
import { useParams, useHistory } from "react-router-dom";
import { PRODUCTS_URL, ASSETS_URL } from "../Consts/config";
import ProductProperties from "../Components/Views/Product/ProductProperties";
import OrderButton from "../Components/Views/Product/OrderButton";
import { I18nContext } from "../I18n";
import ProductImage from "../Components/Views/Product/ProductImage";

const Product = () => {
  const params = useParams();
  const history = useHistory();
  const { translate } = useContext(I18nContext);

  const [productInfo, setProductInfo] = useState();

  useEffect(() => {
    const productId = params?.id;
    if (productId) {
      const product = translate(`data.products.${productId}`);
      if (product.id) {
        setProductInfo(product);
      } else {
        history.push(PRODUCTS_URL);
      }
    } else {
      history.push(PRODUCTS_URL);
    }
  }, [params, history, translate]);

  if (productInfo) {
    return (
      <AqPageLayout
        backText={translate("pages.product.back_text")}
        title={productInfo.name}
      >
        <div className="product">
          <ProductImage image={`${ASSETS_URL}/${productInfo.image}`} />
          <div>
            <ProductProperties
              properties={productInfo.properties}
              name={productInfo.name}
            />
            <OrderButton productInfo={productInfo} />
          </div>
        </div>
      </AqPageLayout>
    );
  } else {
    return <div></div>;
  }
};

export default Product;
