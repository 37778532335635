import React, { useContext } from "react";
import { I18nContext } from "../../../I18n";
import { InfoCircleFilled } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";

const { Title } = Typography;

const ItemRows = ({
  city,
  regular,
  sparkling,
  filtered,
  showFilteredWater,
}) => {
  const { translate } = useContext(I18nContext);

  const formatMeasurement = (measurement) => {
    if ((measurement !== 0 && !measurement) || measurement < 0) {
      return "-";
    } else {
      return `${parseInt(measurement).toString()} ${translate(
        "pages.results.mg_per_liter"
      )}`;
    }
  };

  const waterQualityPrecentage = (base, value, abs = false) => {
    base = parseFloat(base);
    value = parseFloat(value);
    const avg = (base + value) / 2;
    const diff = value - base;
    const percentage = (diff / avg) * 100;
    return abs ? Math.abs(percentage) : percentage;
  };

  const renderWarning = (base, id) => {
    const regularData = regular.measurementData;
    const sparklingData = sparkling.measurementData;
    return (
      (allValidMeasurements([base[id], regularData[id]]) &&
        waterQualityPrecentage(base[id], regularData[id]) > 10) ||
      (allValidMeasurements([base[id], sparklingData[id]]) &&
        waterQualityPrecentage(base[id], sparklingData[id]) > 10)
    );
  };

  const allValidMeasurements = (measurements) => !measurements.includes(-1);

  const diffText = (mineral, city, preferred, preferredName, baseName) => {
    if (!allValidMeasurements([city, preferred])) return false;
    const diff = waterQualityPrecentage(city, preferred);
    if (diff > 10) {
      const direction = translate(
        `pages.results.${diff < 10 ? "higher" : "lower"}`
      );
      return translate("pages.results.compare_water_quality_tooltip", {
        mineral,
        baseName,
        direction,
        name: preferredName,
      });
    }
    return false;
  };

  const generateTooltipText = (id, base, baseName) => {
    let texts = [];
    const mineral = translate(`data.measurements.${id}.name`);
    const regularDiff = diffText(
      mineral,
      base[id],
      regular.measurementData[id],
      regular.name,
      baseName
    );
    const sparkDiff = diffText(
      mineral,
      base[id],
      sparkling.measurementData[id],
      sparkling.name,
      baseName
    );

    regularDiff && texts.push(regularDiff);
    sparkDiff && texts.push(sparkDiff);

    if (!regularDiff && !sparkDiff) {
      texts.push(
        translate("pages.results.good_tap_water_tooltip", { mineral })
      );
    }
    const divs = (
      <div>
        {texts.map((text, index) => (
          <div key={index} className="results__table-tooltip-item">
            {text}
          </div>
        ))}
      </div>
    );
    return divs;
  };

  const mineralItem = (value, showTooltip) => {
    return (
      <div className="with-tooltip">
        <p>{formatMeasurement(value)}</p>
        {showTooltip && (
          <InfoCircleFilled className="results__table-row__info" />
        )}
      </div>
    );
  };

  const rowsGroup = (row, title, showWarnings, isLastRow) => {
    const classes = isLastRow
      ? "results__table-row results__table-footer-row"
      : "results__table-row";
    return (
      <div className={classes}>
        <div className="results__table-row__right-divider results__table-row__first">
          <Title level={4} className="typography h4">
            {translate(`pages.results.${title}`)}
          </Title>
          <p>{row.name}</p>
        </div>

        {Object.entries(row.measurementData).map(([key, value]) => {
          if (
            showWarnings &&
            translate(`data.measurements.${key}.showTooltip`) &&
            renderWarning(city.measurementData, key)
          ) {
            return (
              <Tooltip
                title={generateTooltipText(
                  key,
                  city.measurementData,
                  translate("pages.results.tap-water")
                )}
              >
                {mineralItem(value, true)}
              </Tooltip>
            );
          } else {
            return mineralItem(value, false);
          }
        })}
      </div>
    );
  };

  return (
    <div>
      {rowsGroup(city, "tap_water_title", true, false)}
      {showFilteredWater &&
        rowsGroup(filtered, "filter_water_title", false, false)}
      {rowsGroup(regular, "regular_water_title", false, false)}
      {rowsGroup(sparkling, "sparkling_water_title", false, true)}
    </div>
  );
};

export default ItemRows;
