import React from "react";
import { Card } from "antd";


const AqCard = ({children}) => {
  return (
    <Card className="aqcard">
      {children}
    </Card>

  );
};

export default AqCard;
