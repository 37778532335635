import React, { useState, useEffect, useContext } from "react";
import AqItem from "../../Core/AqItem";
import AqSelect from "../../Core/AqSelect";
import { getWaters } from "../../../Apis/waters";
import waterIcon from "../../../Assets/Icons/regular_water.svg";
import bottleIcon from "../../../Assets/Icons/bottle.svg";
import sparklingWaterIcon from "../../../Assets/Icons/sparkling_water.svg";
import waterTypes from "../../../Enums/waterTypes";
import { sortByProp } from "../../../Utils/listOperations";
import { I18nContext } from "../../../I18n";
import { message } from "antd";
import { MESSAGE_DURATION } from "../../../Consts/config";
import { isSuccessful, toAqResponse } from "../../../Utils/responses";
import { s500 } from "../../../Consts/responses";
import { getById } from "../../../Utils/dataSort";

const WaterFields = ({ water, sparkling, country, onChange }) => {
  const [waters, setWaters] = useState([]);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    (async () => {
      try {
        const watersResRaw = await getWaters();
        const watersRes = toAqResponse(watersResRaw.data, s500.SERVER_ERROR);
        if (!isSuccessful(watersRes)) throw watersRes;
        const watersData = watersRes.data.map((filter) => {
          const countries = filter.countries.map((country) => country.id);
          return { ...filter, countries };
        });
        setWaters(sortByProp(watersData, "name"));
      } catch (error) {
        message.error(
          translate("responses.ERROR_LOAD_WATERS"),
          MESSAGE_DURATION
        );
        console.error(`❌ ${error}`);
      }
    })();
  }, [translate]);

  const renderImage = (imgSrc) => {
    return <img src={imgSrc} alt="icon" />;
  };

  const onChangeWater = (newValue) =>
    onChange(waterTypes.REGULAR, getById(waters, newValue));
  const onChangeSparkling = (newValue) =>
    onChange(waterTypes.SPARKLING, getById(waters, newValue));

  const filterByTypeAndCountry = (type) =>
    waters.filter(
      (water) => water.type === type && water.countries.includes(country)
    );

  return (
    <div>
      <div className="home__form-items__icon">
        <img src={bottleIcon} alt="icon" />
      </div>
      <AqItem label={renderImage(waterIcon)}>
        <AqSelect
          placeholder={translate("pages.home.regular_water_input_placeholder")}
          value={water}
          onChange={onChangeWater}
          options={filterByTypeAndCountry(waterTypes.REGULAR)}
        />
      </AqItem>
      <AqItem label={renderImage(sparklingWaterIcon)}>
        <AqSelect
          placeholder={translate(
            "pages.home.sparkling_water_input_placeholder"
          )}
          value={sparkling}
          onChange={onChangeSparkling}
          options={filterByTypeAndCountry(waterTypes.SPARKLING)}
        />
      </AqItem>
    </div>
  );
};

export default WaterFields;
