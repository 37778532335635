import React, { useContext } from "react";
import { Typography } from "antd";
import { I18nContext } from "../../../../I18n";
const { Title } = Typography;

const OrderComplete = () => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="product__order-modal">
      <Title level={3} className="typography h3">
        {translate("pages.product.order_modal.complete_title")}
      </Title>
      <div>
        {translate("pages.product.order_modal.complete_content").map(
          (text, index) => (
            <p key={index}>{text}</p>
          )
        )}
      </div>
    </div>
  );
};

export default OrderComplete;
