import React from "react";
import { Layout, Typography, Button } from "antd";
import AppIcon from "../Shared/AppIcon";
import AqFooter from "../Shared/AqFooter";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const { Footer, Content } = Layout;
const { Title } = Typography;

const AqPageLayout = ({ children, title, backText, logoClickable = true }) => {
  const history = useHistory();

  const onClickBack = () => history.goBack();
  return (
    <Layout className="page-layout">
      <div>
        <div className="page-layout__header">
          <AppIcon
            className="page-layout__header-logo"
            clickable={logoClickable}
          />
          {backText && (
            <Button type="text" onClick={onClickBack}>
              <LeftOutlined />
              {backText}
            </Button>
          )}
        </div>
        <div className="page-layout__header-title">
          <Title className="typography h2" level={2}>
            {title}
          </Title>
        </div>
      </div>
      <Content>
        <div>{children}</div>
      </Content>
      <Footer className="page-layout__footer">
        <AqFooter />
      </Footer>
    </Layout>
  );
};

export default AqPageLayout;
