import React, { useContext } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { I18nContext } from "../../../I18n";

const { Title } = Typography;

const ProductProperties = ({ properties, name }) => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="product__properties">
      <Title level={4} className="product__properties-title typography h4">
        {translate("pages.product.properties_title", { name })}
      </Title>
      {properties.map((property, index) => (
        <div key={index} className="product__properties-item">
          <CheckOutlined className="product__properties-item-icon" />
          <p>{property}</p>
        </div>
      ))}
    </div>
  );
};

export default ProductProperties;
