import React from "react";
import { Select } from "antd";

const { Option } = Select;

const AqSelect = ({
  placeholder,
  options,
  onChange,
  value,
  valueKey = "id",
  displayKey = "name",
}) => {
  return (
    <Select
      bordered={false}
      showSearch
      className="aqinput"
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      onFocus={() => {}}
      onBlur={() => {}}
      onSearch={() => {}}
      value={value}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options &&
        options.map((option) => (
          <Option
            key={option[valueKey]}
            disabled={option.disabled}
            value={option[valueKey]}
            className={option.disabled && "typography italic"}
          >
            {option[displayKey]}
          </Option>
        ))}
    </Select>
  );
};

export default AqSelect;
