import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import OrderForm from "./OrderForm";
import OrderComplete from "./OrderComplete";

const OrderProductModal = ({ visibleState, onClose, productInfo }) => {
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (visibleState) setCompleted(false);
  }, [visibleState]);

  const onSubmitForm = () => {
    setCompleted(true);
  };

  return (
    <Modal visible={visibleState} footer={false} onCancel={onClose}>
      {completed ? (
        <OrderComplete />
      ) : (
        <OrderForm onSubmit={onSubmitForm} productInfo={productInfo} />
      )}
    </Modal>
  );
};

export default OrderProductModal;
