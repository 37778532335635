import React, { useState } from "react";
import WaterSearchContext from "./context";

const WaterSearchProvider = ({ children }) => {
  const setWaterSearch = (waterSearch) => {
    updateWaterSearch((prevState) => {
      return { ...prevState, waterSearch: { ...waterSearch } };
    });
  };
  const waterSearchState = {
    waterSearch: {},
    setWaterSearch,
  };
  const [waterSearch, updateWaterSearch] = useState(waterSearchState);

  return (
    <WaterSearchContext.Provider value={waterSearch}>
      {children}
    </WaterSearchContext.Provider>
  );
};

export default WaterSearchProvider;
