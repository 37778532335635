import React from "react";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
const AqTooltip = ({ text }) => {
  return (
    <Tooltip title={text}>
      <div className="aqtooltip">
        <QuestionCircleOutlined />
      </div>
    </Tooltip>
  );
};

export default AqTooltip;
