import AqResponse from "../Models/AqResponse";

const s400 = {
  MISSING_ARGUMENTS: new AqResponse(400, "MISSING_ARGUMENTS"),
  MISSING_COUNTRY: new AqResponse(400, "MISSING_COUNTRY"),
  MISSING_REGULAR_WATER: new AqResponse(400, "MISSING_REGULAR_WATER"),
  MISSING_SPARKLING_WATER: new AqResponse(400, "MISSING_SPARKLING_WATER"),
  INVALID_EMAIL: new AqResponse(400, "INVALID_EMAIL"),
  MISSING_PASSWORD: new AqResponse(400, "MISSING_PASSWORD"),
  MISSING_NAME: new AqResponse(400, "MISSING_NAME"),
  MISSING_FILTER: new AqResponse(400, "MISSING_FILTER"),
};

const s500 = {
  SERVER_ERROR: new AqResponse(500, "SERVER_ERROR"),
  GOOGLE_MAPS_API_ERROR: new AqResponse(500, "GOOGLE_MAPS_API_ERROR"),
  ERROR_LOAD_WATERS: new AqResponse(500, "ERROR_LOAD_WATERS"),
  ERROR_LOAD_LOCATIONS: new AqResponse(500, "ERROR_LOAD_LOCATIONS"),
  ERROR_WATER_QUALITY_QUERY: new AqResponse(500, "ERROR_WATER_QUALITY_QUERY"),
  NO_INFO_FOUND: new AqResponse(500, "NO_INFO_FOUND"),
};

export { s400, s500 };
