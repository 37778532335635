import React, { useContext } from "react";
import AqLandingLayout from "../Components/Layouts/Landing";
import { Typography } from "antd";
import { I18nContext } from "../I18n";

const { Title } = Typography;

const TermsOfService = () => {
  const { translate } = useContext(I18nContext);

  return (
    <AqLandingLayout>
      <div className="empty-page">
        <Title className="typography h1" level={1}>
          {translate("pages.terms_of_service.title")}
        </Title>
        <p>{translate("pages.terms_of_service.content")}</p>
      </div>
    </AqLandingLayout>
  );
};

export default TermsOfService;
