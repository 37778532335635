import AqResponse from "../Models/AqResponse";

const extractNameByType = (address, addressType) => {
  const addressComponents = address.address_components || [];
  if (addressComponents.length < 1) return undefined;
  for (const component of addressComponents) {
    const componentTypes = component.types || [];
    if (componentTypes.length < 1) return undefined;
    for (const type of componentTypes) {
      if (type.toString() === addressType) {
        return component.long_name;
      }
    }
  }
  return undefined;
};

const extractGeoLocation = (address) => {
  const geoLocation = address?.geometry?.location || {};
  return [geoLocation.lat, geoLocation.lng];
};

const formateGeoApiRes = (res) => {
  try {
    const statusText = res?.status;
    const status = statusText === "OK" ? 200 : 500;
    return new AqResponse(
      status,
      `GOOGLE_MAPS_API_${statusText}`,
      res?.results
    );
  } catch (error) {
    return false;
  }
};

const formateAddress = (address) => {
  let formattedAddress = [];
  const toSkip = ["postal_code", "country", "street_number", "administrative_area_level_2"];
  const addressComponents = address.address_components || [];
  if (addressComponents.length < 1) return undefined;
  for (const component of addressComponents) {
    const componentTypes = component.types || [];
    if (componentTypes.length < 1) continue;
    if (!componentTypes.some((type) => toSkip.indexOf(type) >= 0))
      formattedAddress.push(component.long_name);
  }
  return formattedAddress.join(", ");
};

const extractCountryName = (address) => extractNameByType(address, "country");
const extractCityName = (address) => extractNameByType(address, "locality");
const extractZip = (address) => extractNameByType(address, "postal_code");

export {
  extractCountryName,
  extractCityName,
  extractGeoLocation,
  extractZip,
  formateGeoApiRes,
  formateAddress,
};
