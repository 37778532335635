import React from "react";
import { Typography } from "antd";

const DividerRow = ({ title }) => {
  const { Title } = Typography;

  return (
    <div className="products__table-divider-row">
      <div className="products__table-divider-row__title">
        <Title level={4} className="typography h4">
          {title}
        </Title>
      </div>
      <div className="products__table-divider-row__divider"></div>
    </div>
  );
};

export default DividerRow;
