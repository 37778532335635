import Axios from "axios";
import history from "../Utils/history";
import { DEFAUL_FALLBACK_URL, TOKEN_NAME } from "../Consts/config";
import { removeToken, getToken } from "../Utils/cookies";

const instance = Axios.create({
  withCredentials: true,
});

const post = async (url, data) => {
  try {
    const res = await instance.post(url, { ...data, [TOKEN_NAME]: getToken() });
    return res;
  } catch (error) {
    const errorCode = error?.response?.data?.code;
    if (errorCode === "NOT_VALID_TOKEN") {
      signout();
    }
    throw error;
  }
};

const get = async (url, data) => {
  try {
    const res = await instance.get(url, { params: {...data, [TOKEN_NAME]: getToken() }});
    return res;
  } catch (error) {
    const errorCode = error?.response?.data?.code;
    if (errorCode === "NOT_VALID_TOKEN") {
      signout();
    }
    throw error;
  }
};

const signout = () => {
  removeToken();
  history.push(DEFAUL_FALLBACK_URL);
  history.go();
};

export { post, get };
