import AqResponse from "../Models/AqResponse";

const withData = (response, data) => {
  response.data = data;
  return response;
};

const isValid = (response) => {
  return response.objectType === "AqResponse";
};

const isSuccessful = (response) =>
  response.status >= 200 && response.status < 300;

const toAqResponse = (response, defaultResponse = {}) =>
  new AqResponse(
    response.status || defaultResponse.status || 400,
    response.code || defaultResponse.status,
    response.data || defaultResponse.status
  );

export { withData, isValid, isSuccessful, toAqResponse };
