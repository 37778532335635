import React, { useContext } from "react";
import AqPageLayout from "../Components/Layouts/Page";
import ProductsTable from "../Components/Views/Products/ProductsTable";
import { I18nContext } from "../I18n";

const Products = () => {
  const { translate } = useContext(I18nContext);

  return (
    <AqPageLayout backText={translate("pages.products.back_text")} title={translate("pages.products.title")}>
      <div className="products">
        <ProductsTable />
      </div>
    </AqPageLayout>
  );
};

export default Products;
