import React from "react";
import ItemRows from "./ItemRows";
import HeaderRow from "./HeaderRow";

const CompareTable = ({
  cityMeasurements,
  locationName,
  regularWater,
  sparklingWater,
  filteredWater,
  showFilteredWater,
}) => {
  const cityWater = {
    measurementData: cityMeasurements,
    name: locationName,
    type: "city",
  }
  return (
    <div className="results__table-wrapper">
      <div className="results__table">
        <HeaderRow
          measurements={Object.keys(cityMeasurements)}
        />
        <ItemRows
          city={cityWater}
          regular={regularWater}
          sparkling={sparklingWater}
          filtered={filteredWater}
          showFilteredWater={showFilteredWater}
        />
        {/* <FooterRow
          regularPrice={regularWater.price}
          sparklingPrice={sparklingWater.price}
          filteredPrice={filteredWater.price}
          showFilteredWater={showFilteredWater}
        /> */}
      </div>
    </div>
  );
};

export default CompareTable;
