// credentials
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL =
  process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// apis
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const COUNTRIES_API_URL = "/countries";
export const WATERS_API_URL = "/waters";
export const ORDERS_API_URL = "/orders";
export const WATER_QUALITY_API_URL = "/water-quality";
export const USERS_API_URL = "/users";
export const FILTERS_API_URL = "/filters";
export const SIGNIN_API_URL = `${USERS_API_URL}/signin`;
export const VALID_TOKEN_API_URL = `${USERS_API_URL}/valid-token`;

// routes
export const HOME_URL = "/";
export const RESULTS_URL = "/results";
export const PRODUCTS_URL = "/products";
export const PRODUCT_URL = "/product";
export const ABOUT_URL = "https://aqvita.com/#about";
export const TERMS_OF_SERVICE_URL = "https://aqvita.com/tos";
export const CONTACT_URL = "https://aqvita.com/#contact";
export const PRIVACY_POLICY_URL = "https://aqvita.com/policy";
export const NOT_FOUND_URL = "/404";
export const DEFAUL_FALLBACK_URL = NOT_FOUND_URL;
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const ASSETS_URL = `${PUBLIC_URL}/assets`;

// config
export const MESSAGE_DURATION = 3;
export const TOKEN_NAME = "token";
