import { s400 } from "../Consts/responses";

const validEmail = (email) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(String(email).toLowerCase())) throw s400.INVALID_EMAIL;
  return true;
};

const validName = (name) => {
  if (emptyString(name)) throw s400.MISSING_NAME;
  return true;
};

const validPassword = (password) => {
  if (emptyString(password)) throw s400.MISSING_PASSWORD;
  return true;
};

const validCountry = (country) => {
  if (emptyString(country) || country.id < 1) throw s400.MISSING_COUNTRY;
  return true;
};

const validRegularWater = (regularWater) => {
  if (emptyString(regularWater) || regularWater.id < 1)
    throw s400.MISSING_REGULAR_WATER;
  return true;
};

const validSparklingWater = (sparklingWater) => {
  if (emptyString(sparklingWater) || sparklingWater.id < 1)
    throw s400.MISSING_SPARKLING_WATER;
  return true;
};

const validFilter = (filter) => {
  if (emptyString(filter) || filter.id < 1) throw s400.MISSING_FILTER;
  return true;
};

const emptyString = (string) => !string || string.toString().trim().length < 1;

export {
  emptyString,
  validEmail,
  validName,
  validPassword,
  validCountry,
  validFilter,
  validRegularWater,
  validSparklingWater,
};
