import { useEffect, useState } from "react";
import { DEFAUL_FALLBACK_URL, TOKEN_NAME } from "../../Consts/config";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';

const RouteProtector = ({ component, fallback }) => {
  const history = useHistory();
  const [hasCookie, setHasCookie] = useState(false);

  useEffect(() => {
    (async () => {
      const cookie = Cookies.get(TOKEN_NAME);
      setHasCookie(!!cookie);
      if (!cookie) {
        history.push(fallback || DEFAUL_FALLBACK_URL);
      }
    })();
  }, [setHasCookie, history, fallback]);

  if (hasCookie) {
    return component;
  } else {
    return <div></div>;
  }
};

export default RouteProtector;
