import React, { useContext, useState } from "react";
import AqButton from "../../Core/AqButton";
import { I18nContext } from "../../../I18n";
import OrderProductModal from "./OrderModal/OrderProductModal";

const OrderButton = ({ productInfo }) => {
  const { translate } = useContext(I18nContext);

  const [visibleOrderModal, setVisibleOrderModal] = useState(false);

  const onOpenOrderModal = () => setVisibleOrderModal(true);
  const onCloseOrderModal = () => setVisibleOrderModal(false);

  const orderText = translate(
    `pages.product.${productInfo.available ? "order" : "pre_order"}`
  );
  return (
    <div>
      <div className="product__order">
        <AqButton
          type="primary"
          onClick={onOpenOrderModal}
          productInfo={productInfo}
        >
          {orderText}
        </AqButton>
      </div>
      <OrderProductModal
        visibleState={visibleOrderModal}
        onClose={onCloseOrderModal}
        productInfo={productInfo}
      />
    </div>
  );
};

export default OrderButton;
