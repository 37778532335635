import React from "react";
import { Layout } from "antd";
import AqFooter from "../Shared/AqFooter";

const { Footer, Content } = Layout;

const AqLandingLayout = ({ children, showFooter = true }) => {
  return (
    <Layout className="landing-layout">
      <Content>
        <div>{children}</div>
      </Content>
      {showFooter && (
        <Footer className="landing-layout__footer">
          <AqFooter />
        </Footer>
      )}
    </Layout>
  );
};

export default AqLandingLayout;
