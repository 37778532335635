import React from "react";
import { Input } from "antd";

const AqInput = ({ placeholder, onChange, value, type = "text", onEnter }) => {
  const defaultInput = (
    <Input
      bordered={false}
      className="aqinput"
      placeholder={placeholder}
      onChange={onChange}
      type={type}
      value={value}
      onKeyDown={(e) => onEnter && e.keyCode === 13 && onEnter()}
    />
  );

  const passwordInput = (
    <Input.Password
      bordered={false}
      className="aqinput"
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onKeyDown={(e) => onEnter && e.keyCode === 13 && onEnter()}
    />
  );

  if (type === "password") {
    return passwordInput;
  } else {
    return defaultInput;
  }
};

export default AqInput;
