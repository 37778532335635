import React from "react";

const AqItem = ({ label, children }) => {
  return (
    <div className="aqitem">
      <div className="aqitem__label">{label}</div>
      {children}
    </div>
  );
};

export default AqItem;
