import React, { useContext } from "react";
import { Typography } from "antd";
import SearchForm from "../Components/Views/Home/SearchForm";
import AqLandingLayout from "../Components/Layouts/Landing";
import { I18nContext } from "../I18n";
import AqBeta from "../Components/Shared/AqBeta";
import AppIcon from "../Components/Shared/AppIcon";
import Particles from "react-particles-js";

const { Title } = Typography;

const Home = () => {
  const { translate } = useContext(I18nContext);

  return (
    <AqLandingLayout>
      <div className="home-background">
        <Particles
          height={window.outerHeight}
          params={{
            particles: {
              number: {
                value: 70,
              },
              size: {
                value: 5,
              },
              move: {
                speed: 2,
              },
            },
          }}
        />
      </div>
      <div className="home-container">
        <AppIcon className="page-layout__header-logo white home-logo" />
        <div className="home">
          <AqBeta />
          <div className="home__title">
            <Title className="typography h1 white" level={1}>
              {translate("pages.home.header")}
            </Title>
            <Title className="typography h2 white" level={2}>
              {translate("pages.home.subheader")}
            </Title>
          </div>
          <SearchForm />
        </div>
      </div>
    </AqLandingLayout>
  );
};

export default Home;
