import Cookies from "js-cookie";
import { TOKEN_NAME } from "../Consts/config";

const setToken = (token) =>
  Cookies.set(TOKEN_NAME, token, { secure: true, sameSite: "None", expires: 7 });

const getToken = () => Cookies.get(TOKEN_NAME);

const removeToken = () => Cookies.remove(TOKEN_NAME);

export { setToken, getToken, removeToken };
