import React from "react";
import Home from "../Views/Home";
import Results from "../Views/Results";
import NotFound from "../Views/NotFound";
import Products from "../Views/Products";
import Product from "../Views/Product";
import {
  HOME_URL,
  RESULTS_URL,
  PRODUCTS_URL,
  PRODUCT_URL,
  ABOUT_URL,
  TERMS_OF_SERVICE_URL,
  CONTACT_URL,
  PRIVACY_POLICY_URL,
  NOT_FOUND_URL,
} from "./config";
import About from "../Views/About";
import TermsOfService from "../Views/TermsOfService";
import Contact from "../Views/Contact";
import PrivacyPolicy from "../Views/PrivacyPolicy";

const routes = [
  {
    path: HOME_URL,
    component: <Home />,
    exact: true,
    isGuarded: false,
  },
  {
    path: RESULTS_URL,
    component: <Results />,
    exact: true,
    isGuarded: false,
  },
  {
    path: PRODUCTS_URL,
    component: <Products />,
    exact: true,
    isGuarded: false,
  },
  {
    path: `${PRODUCT_URL}/:id`,
    component: <Product />,
    exact: true,
    isGuarded: false,
  },
  {
    path: ABOUT_URL,
    component: <About />,
    exact: true,
    isGuarded: false,
  },
  {
    path: TERMS_OF_SERVICE_URL,
    component: <TermsOfService />,
    exact: true,
    isGuarded: false,
  },
  {
    path: CONTACT_URL,
    component: <Contact />,
    exact: true,
    isGuarded: false,
  },
  {
    path: PRIVACY_POLICY_URL,
    component: <PrivacyPolicy />,
    exact: true,
    isGuarded: false,
  },
  {
    path: NOT_FOUND_URL,
    component: <NotFound />,
    exact: false,
    isGuarded: false,
  },
];

export default routes;
