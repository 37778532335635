import React from "react";
import { Button } from "antd";

const AqButton = ({ children, type, size, shape, onClick, icon, disabled }) => {
  return (
    <Button
      type={type}
      size={size}
      shape={shape}
      onClick={onClick}
      icon={icon}
      disabled={disabled}
      className="aqbutton"
    >
      {children}
    </Button>
  );
};

export default AqButton;
