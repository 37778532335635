import React, { useState, useEffect, useContext } from "react";
import AqItem from "../../Core/AqItem";
import AqSelect from "../../Core/AqSelect";
import { getCountries } from "../../../Apis/countries";
import globIcon from "../../../Assets/Icons/glob.svg";
import locationIcon from "../../../Assets/Icons/location.svg";
import homeIcon from "../../../Assets/Icons/address.svg";
import tapIcon from "../../../Assets/Icons/tap.svg";
import filterIcon from "../../../Assets/Icons/filter.svg";
import AqInput from "../../Core/AqInput";
import { sortByProp } from "../../../Utils/listOperations";
import { I18nContext } from "../../../I18n";
import { message, Button } from "antd";
import { MESSAGE_DURATION } from "../../../Consts/config";
import { toAqResponse, isSuccessful } from "../../../Utils/responses";
import { s500 } from "../../../Consts/responses";
import { getFilters } from "../../../Apis/filters";
import { getById } from "../../../Utils/dataSort";

const AddressFields = ({
  country,
  address,
  zip,
  filter,
  advancedOpened,
  onChange,
}) => {
  const { translate } = useContext(I18nContext);
  const [countries, setCountries] = useState([]);
  const [filters, setFilters] = useState([]);

  const toggleAdvancedOptions = () => {
    onChange("advancedOpened", !advancedOpened);
  };

  const onChangeCountry = (newCountry) =>
    onChange("country", getById(countries, newCountry));
  const onChangeAddress = (newAddress) =>
    onChange("address", newAddress.target.value);
  const onChangeZip = (newZip) => onChange("zip", newZip.target.value);
  const onChangeFilter = (newFilter) =>
    onChange("filter", getById(filters, newFilter));

  useEffect(() => {
    (async () => {
      try {
        const countriesResRaw = await getCountries();
        const countriesRes = toAqResponse(
          countriesResRaw.data,
          s500.SERVER_ERROR
        );
        if (!isSuccessful(countriesRes)) throw countriesRes;
        setCountries(
          sortByProp(sortByProp(countriesRes.data, "name"), "available", false)
        );
      } catch (error) {
        message.error(
          translate("responses.ERROR_LOAD_LOCATIONS"),
          MESSAGE_DURATION
        );
        console.error(`❌ ${error}`);
      }
    })();

    (async () => {
      try {
        const filtersResRaw = await getFilters();
        const filtersRes = toAqResponse(filtersResRaw.data, s500.SERVER_ERROR);
        if (!isSuccessful(filtersRes)) throw filtersRes;
        const filtersData = filtersRes.data.map((filter) => {
          const countries = filter.countries.map((country) => country.id);
          return { ...filter, countries };
        });
        setFilters(sortByProp(filtersData, "name"));
      } catch (error) {
        message.error(
          translate("responses.ERROR_LOAD_FILTERS"),
          MESSAGE_DURATION
        );
        console.error(`❌ ${error}`);
      }
    })();
  }, [translate]);

  const renderImage = (imgSrc) => {
    return <img src={imgSrc} alt="icon" />;
  };

  return (
    <div>
      <div className="home__form-items__icon">
        <img src={tapIcon} alt="icon" />
      </div>
      <AqItem label={renderImage(globIcon)}>
        <AqSelect
          placeholder={translate("pages.home.country_input_placeholder")}
          value={country}
          onChange={onChangeCountry}
          options={countries.map((country) =>
            !country.available
              ? {
                  ...country,
                  name: `${country.name} - Comming soon`,
                  disabled: true,
                }
              : country
          )}
        />
      </AqItem>
      <AqItem label={renderImage(homeIcon)}>
        <AqInput
          placeholder={translate("pages.home.address_input_placeholder")}
          value={address}
          onChange={onChangeAddress}
        />
      </AqItem>
      <AqItem label={renderImage(locationIcon)}>
        <AqInput
          placeholder={translate("pages.home.zip_input_placeholder")}
          value={zip}
          onChange={onChangeZip}
          type="number"
        />
      </AqItem>
      {advancedOpened && (
        <AqItem label={renderImage(filterIcon)}>
          <AqSelect
            placeholder={translate("pages.home.filter_input_placeholder")}
            value={filter}
            onChange={onChangeFilter}
            options={filters.filter((filter) =>
              filter.countries.includes(country)
            )}
          />
        </AqItem>
      )}
      <div className="home__form-items__more-button">
        <Button
          type="text"
          className="typography caption"
          onClick={toggleAdvancedOptions}
        >
          {translate(
            `pages.home.${
              advancedOpened ? "close_advanced_options" : "advanced_options"
            }`
          )}
        </Button>
      </div>
    </div>
  );
};

export default AddressFields;
