import { WATER_QUALITY_API_URL, SERVER_URL } from "../Consts/config";
import { post } from ".";

const getWaterQuality = async (address, waters, filter) => {
  const data = {
    address,
    waters,
    filter,
  };
  const res = await post(`${SERVER_URL}${WATER_QUALITY_API_URL}`, data);
  return res;
};

export { getWaterQuality };
