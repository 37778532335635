import React from "react";
import { useHistory } from "react-router-dom";
import { PRODUCT_URL, ASSETS_URL } from "../../../Consts/config";

const HeaderRow = ({ productsInfo }) => {
  const history = useHistory();

  const onClickProduct = (productId) => {
    history.push(`${PRODUCT_URL}/${productId}`);
  };

  return (
    <div className="products__table-header-row">
      {Object.values(productsInfo).map((product) => (
        <div key={product.id} onClick={() => onClickProduct(product.id)}>
          <div className="products__table-header-row__image">
            <img src={`${ASSETS_URL}/${product.smallImage}`} alt="product" />
          </div>
          <p>{product.name}</p>
        </div>
      ))}
    </div>
  );
};

export default HeaderRow;
