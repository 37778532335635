import React, { useEffect, useState, useContext } from "react";
import AqPageLayout from "../Components/Layouts/Page";
import CompareTable from "../Components/Views/Results/CompareTable";
import { useLocation, useHistory } from "react-router-dom";
import { HOME_URL, PRODUCTS_URL } from "../Consts/config";
import AqButton from "../Components/Core/AqButton";
import waterTypes from "../Enums/waterTypes";
import { I18nContext } from "../I18n";
import { getById } from "../Utils/dataSort";

const Results = () => {
  const location = useLocation();
  const history = useHistory();
  const { translate } = useContext(I18nContext);

  const [resData, setResData] = useState();

  useEffect(() => {
    const res = location?.state || {};
    const cityMeasurements = res.measurementRes || {};
    const locationName =
      res.locationRes?.displayName ||
      res.locationRes?.city ||
      res.userInput?.country?.name ||
      "unknown";
    const filterName = res.userInput?.filter?.name || "unknown";
    const showFilteredWater = res.userInput.advancedOpened;
    let regularWater = res.userInput[waterTypes.REGULAR];
    regularWater.measurementData = getById(
      res.watersRes,
      regularWater.id
    )?.measurementDatum;
    let filteredWater = res.userInput.filter || {};
    if (showFilteredWater) {
      filteredWater.measurementData = res.filterRes.measurementDatum;
    }
    let sparklingWater = res.userInput[waterTypes.SPARKLING];
    sparklingWater.measurementData = getById(
      res.watersRes,
      sparklingWater.id
    )?.measurementDatum;
    if (Object.keys(cityMeasurements).length > 0) {
      setResData({
        cityMeasurements,
        locationName,
        filterName,
        filteredWater,
        regularWater,
        sparklingWater,
        showFilteredWater,
      });
    } else {
      history.push(HOME_URL);
    }
  }, [location, history]);

  const goToProductsPage = () => {
    history.push(PRODUCTS_URL);
  };

  if (resData) {
    return (
      <AqPageLayout
        backText={translate("pages.results.back_text")}
        title={translate("pages.results.title")}
      >
        <div className="results">
          <div>
          </div>
          <CompareTable {...resData} />
          <div className="results__call-to-action">
            <AqButton type="primary" onClick={goToProductsPage}>
              {translate("pages.results.action_button")}
            </AqButton>
            <p className="results__disclaimer typography caption">Disclaimer. While reasonable efforts are used to check and update the Website, all information and materials are provided “as is” and “as available”, and shall not be binding nor shall it be construed as constituting any obligation, representation or warranty on the part of Aqvita. Aqvita does not guarantee the accuracy, adequacy, completeness, timeliness or fitness for any specific purpose of the information and materials included or referred to in the Website or the reliability of their sources. In no event shall Aqvita be liable for any direct, indirect, incidental, special or consequential damages, damages for loss of profits, revenue, goodwill, use of data, incurred by you or any third party, arising from your access to or use of the Website. In particular, water quality at your location and mineral water composition are based on publicly available information and Aqvita disclaims any and all liability as to their accuracy.</p>
          </div>
        </div>
      </AqPageLayout>
    );
  } else {
    return <div></div>;
  }
};

export default Results;
