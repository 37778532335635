import React, { useState, useContext, useEffect } from "react";
import AqCard from "../../Core/AqCard";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import WaterFields from "./WaterFields";
import AddressFields from "./AddressFields";
import { geocodeAddress, addressToWayId } from "../../../Apis/maps";
import { getWaterQuality } from "../../../Apis/waterQuality";
import {
  extractCityName,
  extractZip,
  extractGeoLocation,
  formateGeoApiRes,
  formateAddress,
} from "../../../Utils/geoApi";
import waterTypes from "../../../Enums/waterTypes";
import { useHistory } from "react-router-dom";
import { RESULTS_URL, MESSAGE_DURATION } from "../../../Consts/config";
import AqButton from "../../Core/AqButton";
import { Spin, message } from "antd";
import { I18nContext } from "../../../I18n";
import { isValid, isSuccessful, toAqResponse } from "../../../Utils/responses";
import { s400, s500 } from "../../../Consts/responses";
import WaterSearchContext from "../../../Contexts/WaterSearchContext/context";
import {
  validCountry,
  validFilter,
  validRegularWater,
  validSparklingWater,
  emptyString,
} from "../../../Utils/validation";

const SearchForm = () => {
  const history = useHistory();
  const { translate } = useContext(I18nContext);

  const { waterSearch, setWaterSearch } = useContext(WaterSearchContext);
  const [selectedFields, setSelectedFields] = useState(waterSearch || {});
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    setWaterSearch(selectedFields);
  }, [selectedFields, setWaterSearch]);

  const validateInput = () => {
    validCountry(selectedFields?.country);
    if (selectedFields?.advancedOpened) validFilter(selectedFields?.filter);
    validRegularWater(selectedFields[waterTypes.REGULAR]);
    validSparklingWater(selectedFields[waterTypes.SPARKLING]);
  };

  const getFullAddress = () =>
    `${selectedFields?.address || ""}, ${selectedFields?.country?.name || ""}`;

  const getWayId = async (address) => {
    const osm = await addressToWayId(address);
    const toSkip = ["bus_stop"];
    for (const place of osm?.data || []) {
      if (!toSkip.includes(place?.type)) return place.osm_id;
    }
    return undefined;
  };

  const searchAddress = async () => {
    try {
      setSearching(true);
      validateInput();
      let address = {}
      if (!emptyString(selectedFields?.zip)) {
        address.countryId = selectedFields?.country?.id;
        address.zip = selectedFields?.zip;
      } else {
        const fullAddress = getFullAddress();
        const geocodeResRaw = await geocodeAddress(fullAddress);
        const geocodeRes = formateGeoApiRes(geocodeResRaw);
        if (!isValid(geocodeRes)) throw s500.GOOGLE_MAPS_API_ERROR;
        if (!isSuccessful(geocodeRes)) throw geocodeRes;
        const addressList = geocodeRes?.data || [];
        if (addressList.length < 1) throw s400.GOOGLE_MAPS_API_ERROR;
        const addressInfo = addressList[0];
        const formattedAddress = formateAddress(addressInfo);
        const zip = extractZip(addressInfo);
        const wayId = await getWayId(
          `${formattedAddress}, ${selectedFields?.country?.name}`
        );
        address.countryId = selectedFields?.country?.id;
        address.city = extractCityName(addressInfo);
        address.geoLocation = extractGeoLocation(addressInfo);
        address.identificationString = wayId;
        address.zip = zip;
      }
      const waters = [
        selectedFields[waterTypes.REGULAR].id,
        selectedFields[waterTypes.SPARKLING].id,
      ];
      const filter =
        selectedFields?.advancedOpened && selectedFields?.filter?.id;
      const waterQualityResRaw = await getWaterQuality(address, waters, filter);
      const waterQualityRes = toAqResponse(
        waterQualityResRaw.data,
        s500.SERVER_ERROR
      );
      if (!isSuccessful(waterQualityRes)) throw waterQualityRes;
      const location = waterQualityRes.data.locationRes;
      setWaterSearch({ ...waterSearch, location });
      history.push(RESULTS_URL, {
        ...waterQualityRes.data,
        userInput: selectedFields,
      });
    } catch (error) {
      const errorCode = error?.response?.data?.code || error?.code;
      console.error(`❌ ${error}`);
      if (translate(`responses.${errorCode}`, {}, true)) {
        message.error(translate(`responses.${errorCode}`), MESSAGE_DURATION);
      } else {
        message.error(
          translate(`responses.${s500.ERROR_WATER_QUALITY_QUERY.code}`),
          MESSAGE_DURATION
        );
      }
    }
    setSearching(false);
  };

  const onChangeSelected = (key, value) => {
    setSelectedFields((prev) => {
      let selected = { ...prev };
      selected[key] = value;
      return selected;
    });
  };

  return (
    <div className="home__form">
      <AqCard>
        <div className="home__form-items">
          <AddressFields
            country={selectedFields?.country?.id}
            address={selectedFields?.address}
            zip={selectedFields?.zip}
            filter={selectedFields?.filter?.id}
            advancedOpened={selectedFields?.advancedOpened}
            onChange={onChangeSelected}
          />
          <WaterFields
            country={selectedFields?.country?.id}
            water={selectedFields[waterTypes.REGULAR]?.id}
            sparkling={selectedFields[waterTypes.SPARKLING]?.id}
            onChange={onChangeSelected}
          />
          <div className="home__form-search-button">
            <AqButton
              type="primary"
              size="large"
              shape="circle"
              onClick={searchAddress}
              disabled={searching}
              icon={
                searching ? (
                  <Spin indicator={<LoadingOutlined spin />} />
                ) : (
                  <SearchOutlined />
                )
              }
            />
          </div>
        </div>
        <div className="home__tos typography caption">Your use of this Website is subject to the Aqwaze Terms of use available here <a href="https://aqvita.com/tos/">https://aqvita.com/tos/</a>. By clicking on the “search” button, you acknowledge to have read and accept the Aqwaze Terms of Use.</div>
        <div className="home__tos typography caption">*Third parties water brands that you can chose from are solely provided to show you how you could produce a water with similar mineral composition using tap water in your location.</div>
      </AqCard>
    </div>
  );
};

export default SearchForm;
