import React, { useReducer } from "react";

import EN from "./en.json";

// To make it easier to read from JSON files
const translations = {
  en: EN,
};

const dotNotationParser = (key, obj) => {
  const value = key.split(".").reduce((o, i) => o[i], obj);
  if (value !== undefined) return value;
  throw Error("value can't be undefined");
};

// This function will be used to create `translate` function for the context
const getTranslate = (langCode) => (key, inject, validate = false) => {
  try {
    let translation = dotNotationParser(key, translations[langCode]);
    const injectRegex = /\{{(.+?)\}}/g;
    const injectMatches = translation.toString().match(injectRegex);
    if (injectMatches) {
      injectMatches.forEach(
        (injectMatch) =>
          (translation = translation.replace(
            injectMatch,
            inject[injectMatch.slice(2, -2)]
          ))
      );
    }
    return validate ? true : translation;
  } catch (e) {
    console.error(`can't find key "${key}" for lang ${langCode}`);
    return validate ? false : key;
  }
};

/* We will have two things in our context state, 
langCode will be the current language of the page
and translate will be the method to translate keys
into meaningful texts. Default language will be Hebrew */
const initialState = {
  langCode: "en",
  translate: getTranslate("en"),
};

export const I18nContext = React.createContext(initialState);

export const I18nContextProvider = ({ children }) => {
  /* This is where magic starts to happen. We're creating
  a reducer to manage the global state which will sit in
  I18nContext. For now, the only action we will have
  is setting language */
  const reducer = (state, action) => {
    switch (action.type) {
      case "setLanguage":
        return {
          langCode: action.payload,
          translate: getTranslate(action.payload),
        };
      default:
        return { ...initialState };
    }
  };

  /* useReducer hook receives a reducer and an initialState to
  return the current state object with a dispatch method to
  dispatch actions. */
  const [state, dispatch] = useReducer(reducer, initialState);

  /* We're Providing state object (langCode and translate method
  in this case) and also the dispatch for the children components */
  return (
    <I18nContext.Provider value={{ ...state, dispatch }}>
      {children}
    </I18nContext.Provider>
  );
};
