import React, { useContext } from "react";
import HeaderRow from "./HeaderRow";
import ItemRow from "./ItemRow";
import FooterRow from "./FooterRow";
import DividerRow from "./DividerRow";
import { I18nContext } from "../../../I18n";

const ProductsTable = () => {
  const { translate } = useContext(I18nContext);

  const productsComapreData = translate("pages.products.compare_table");
  const productsInfo = translate("data.products");

  const productsToIndexs = (products) => {
    let indexs = [];
    Object.keys(productsInfo).forEach((productId) => {
      indexs.push(products.includes(productId));
    });
    return indexs;
  };

  return (
    <div className="products__table-wrapper">
      <div className="products__table">
        <HeaderRow productsInfo={productsInfo} />
        {productsComapreData.map((data, index) => {
          return [
            <DividerRow key={index} title={data.name} />,
            data.values.map((value, index) => {
              return (
                <ItemRow
                  key={index}
                  title={value.name}
                  tooltip={value.tooltip}
                  selections={productsToIndexs(value.products)}
                />
              );
            }),
          ];
        })}
        <DividerRow />
        <FooterRow productsInfo={productsInfo} />
      </div>
    </div>
  );
};

export default ProductsTable;
