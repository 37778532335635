import React, { useContext } from "react";
import AqButton from "../../Core/AqButton";
import { useHistory } from "react-router-dom";
import { PRODUCT_URL } from "../../../Consts/config";
import { I18nContext } from "../../../I18n";

const FooterRow = ({ productsInfo }) => {
  const history = useHistory();
  const { translate } = useContext(I18nContext);

  const onClickOrder = (productId) => {
    history.push(`${PRODUCT_URL}/${productId}`);
  };

  return (
    <div className="products__table-footer-row">
      {Object.values(productsInfo).map((product) => (
        <div key={product.id}>
          <AqButton onClick={() => onClickOrder(product.id)} type="primary">
            {translate(
              `pages.product.${product.available ? "order" : "pre_order"}`
            )}
          </AqButton>
        </div>
      ))}
    </div>
  );
};

export default FooterRow;
