import React, { useContext } from "react";
import { Typography } from "antd";
import { I18nContext } from "../../../I18n";
const { Title } = Typography;

const HeaderRow = ({ measurements }) => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="results__table-header-row">
      <div className="results__table-header-row__item results__table-row__first">
        <Title level={4} className="typography h4">
          {translate("pages.results.minerals_title")}
        </Title>
      </div>
      {measurements.map((measurement) => (
        <div key={measurement} className="results__table-header-row__item">
          <Title level={4} className="typography h4">
            {translate(`data.measurements.${measurement}.name`)}
          </Title>
        </div>
      ))}
    </div>
  );
};

export default HeaderRow;
