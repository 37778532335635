import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "./Consts/routes";
import RouteProtector from "./Components/Shared/RouteProtector";
import { I18nContextProvider } from "./I18n";
import ScrollToTop from "./Hooks/scrollToTop";

import "./App.less";
import ContextProviders from "./Contexts";

const App = () => {
  return (
    <I18nContextProvider>
      <ContextProviders>
        <ScrollToTop />
        <Switch>
          {routes.map((route) => (
            <Route
              key={`key-${route.path}`}
              path={route.path}
              exact={route.exact}
              component={() =>
                route.isGuarded ? (
                  <RouteProtector
                    component={route.component}
                    fallback={route.fallback}
                  />
                ) : (
                  route.component
                )
              }
            />
          ))}
        </Switch>
      </ContextProviders>
    </I18nContextProvider>
  );
};

export default App;
