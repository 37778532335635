import React from "react";
import { useHistory } from "react-router-dom";
import { HOME_URL } from "../../Consts/config";
import { Typography } from "antd";

const { Title } = Typography;

const AppIcon = ({ className, clickable = true }) => {
  const history = useHistory();

  const onClickLogo = () => history.push(HOME_URL);

  return (
    <div
      style={clickable ? { cursor: "pointer" } : {}}
      onClick={clickable ? onClickLogo : () => {}}
      className={className}
    >
      <Title level={2} className="typography h2">
        Aqwaze
      </Title>
    </div>
  );
};

export default AppIcon;
