import Axios from "axios";
import Geocode from "react-geocode";

import { GOOGLE_MAPS_API_KEY } from "../Consts/config";

const geocodeAddress = async (address) => {
  try {
    Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
    const res = await Geocode.fromAddress(address);
    return res;
  } catch (err) {
    throw err;
  }
};

const addressToWayId = async (address) => {
  try {
    const res = await Axios.get(
      `https://nominatim.openstreetmap.org/search.php?q=${address}&format=json`
    );

    return res;
  } catch (err) {
    throw err;
  }
};

export { geocodeAddress, addressToWayId };
